exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-crm-js": () => import("./../../../src/pages/crm.js" /* webpackChunkName: "component---src-pages-crm-js" */),
  "component---src-pages-delete-js": () => import("./../../../src/pages/delete.js" /* webpackChunkName: "component---src-pages-delete-js" */),
  "component---src-pages-employee-wellness-js": () => import("./../../../src/pages/employee-wellness.js" /* webpackChunkName: "component---src-pages-employee-wellness-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-tos-js": () => import("./../../../src/pages/tos.js" /* webpackChunkName: "component---src-pages-tos-js" */)
}

